<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="pa-2"
        outlined
        x-small
        color="red"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>
          mdi-delete
        </v-icon>
      </v-btn>
    </template>

    <v-card dense>
      <v-card-title class="text-center ">
        {{ message }}
      </v-card-title>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red"
          dark
          text
          @click="handleRemove"
        >
          Sim, quero Excluir!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    btnText: {
      default: 'Excluir'
    },
    message: {
      default: 'Tem certeza que deseja Excluir?'
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    handleRemove () {
      this.$emit('remove')
      this.dialog = false
    }
  }
}

</script>
