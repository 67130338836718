<template>
  <tr :class="odd ? 'odd' : 'even'">
    <td class="text-center pa-3">
      <v-img
        :width="80"
        height="80"
        cover
        :src="getImage"
      />
    </td>

    <td class="text-left">
      {{ album.nome }}
    </td>

    <td class="text-left">
      {{ album.descricao }}
    </td>

    <td class="pa-1">
      <v-tooltip
        bottom
        v-if="isAdmin"
      >
        <template #activator="{ on }">
          <v-btn
            class="pa-2 mr-1"
            v-on="on"
            outlined
            x-small
            color="primary"
            @click="editAlbum"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
        <span>Atualizar Album</span>
      </v-tooltip>

      <template v-if="isAdmin">
        <FieldDialogRemove
          @remove="removeAlbum"
          :btn-text="'Remover'"
          :message="'Tem certeza que deseja remover este Álbum? '"
        />
      </template>
    </td>
  </tr>
</template>

<script>
import FieldDialogRemove from '@/components/fields/FieldDialogRemove.vue'
import { ROUTE_ALBUMS_EDIT } from '@/constants'
export default {
  components: {
    FieldDialogRemove
  },
  data () {
    return {
      dialog: false,
      defaultImage: 'https://udv-mdt.s3.amazonaws.com/assets/images/generico.png'
    }
  },
  props: {
    odd: {},
    album: {},
    isAdmin: {}
  },

  computed: {
    getImage () {
      return this.album.imagem || this.defaultImage
    }
  },

  methods: {
    editAlbum () {
      this.$router.push({ name: ROUTE_ALBUMS_EDIT, params: { id: this.album.id } })
    },

    removeAlbum () {
      this.$store.dispatch('removeAlbum', this.album.id)
    }
  }
}
</script>

<style scoped>
  tr.odd>td {
    background-color: #0094d90f
  }
</style>
